class Hex {
	static size = 44
	static dirs = ['east', 'se', 'sw', 'west', 'ne', 'nw']

	constructor(id) {
		this.id = id
		this.adjacent = 0 // number of adjacent mines

		// status values
		this.revealed = false
		this.mine = false
		this.flagged = false

		// positioning
		this.x = 0
		this.y = 0 

		// axial coordinates
		this.q = 0
		this.r = 0

		// links to adjacent hexes
		this.east = null	// +1q +0r | d
		this.se = null		// +0q +1r | x
		this.sw = null		// -1q +1r | z
		this.west = null	// -1q +0r | a
		this.nw = null		// +0q -1r | w
		this.ne = null		// +1q -1r | e
	}

	// get polygon coordinates for SVG
	static getPolygon(offset = {x: 0, y: 0}) {
		let a = [0, 0-Math.round(Hex.size*0.5)]
		let b = [Math.round(Hex.size*0.5), 0-Math.round(Hex.size*0.25)]
		let c = [Math.round(Hex.size*0.5), Math.round(Hex.size*0.25)]
		let d = [0, Math.round(Hex.size*0.5)]
		let e = [0-Math.round(Hex.size*0.5), Math.round(Hex.size*0.25)]
		let f = [0-Math.round(Hex.size*0.5), 0-Math.round(Hex.size*0.25)]
		let arr = [f, a, b, c, d, e]
		arr.forEach((point) => {
			point[0] -= offset.x
			point[1] -= offset.y
		})
		return `${f[0]},${f[1]} ${a[0]},${a[1]} ${b[0]},${b[1]} ${c[0]},${c[1]} ${d[0]},${d[1]} ${e[0]},${e[1]}`
	}

	// get axial coordinates of adjacent hex (or self)
	getAxial(dir = false) {
		switch(dir) {
			case 'east': return {q: this.q+1, r: this.r}
			case 'se': return {q: this.q, r: this.r+1}
			case 'sw': return {q: this.q-1, r: this.r+1}
			case 'west': return {q: this.q-1, r: this.r}
			case 'nw': return {q: this.q, r: this.r-1}
			case 'ne': return {q: this.q+1, r: this.r-1}
			default: return {q: this.q, r: this.r}
		}
	}

	// create a link to an adjacent hex
	link(prev, dir, isNew = false) {
		//console.log('Linking', this, 'to', hex, 'via', dir)
		if (this.id === prev.id) {
			console.log('error linking tile to itself!')
			return
		}
		let axial = prev.getAxial(dir)
		if (isNew) {
			this.q = axial.q
			this.r = axial.r
		}
		if (dir == 'east') {
			this.west = prev
			prev.east = this
			if (isNew) {
				this.x = prev.x + Hex.size
				this.y = prev.y
			}
			return
		}
		if (dir == 'sw') {
			this.ne = prev
			prev.sw = this
			if (isNew) {
				this.x = prev.x - Hex.size*0.5
				this.y = prev.y + Hex.size*0.75
			}
			return
		}
		if (dir == 'west') {
			this.east = prev
			prev.west = this
			if (isNew) {
				this.x = prev.x - Hex.size
				this.y = prev.y
			}
			return
		}
		if (dir == 'nw') {
			this.se = prev
			prev.nw = this
			if (isNew) {
				this.x = prev.x - Hex.size*0.5
				this.y = prev.y - Hex.size*0.75
			}
			return
		}
		if (dir == 'ne') {
			this.sw = prev
			prev.ne = this
			if (isNew) {
				this.x = prev.x + Hex.size*0.5
				this.y = prev.y - Hex.size*0.75
			}
			return
		}
		if (dir == 'se') {
			this.nw = prev
			prev.se = this
			if (isNew) {
				this.x = prev.x + Hex.size*0.5
				this.y = prev.y + Hex.size*0.75
			}
		}
	}

	linkAdjacent(doesTileExist) {
		Hex.dirs.forEach((dir) => {
			let tile = doesTileExist(this, dir)
			if (tile) tile.link(this, dir)
		})
	}

	static getEdgeCoords(x,y) {
		return {
			a: { x: x, y: y - Hex.size*0.5 },
			b: { x: x + Hex.size*0.5, y: y - Hex.size*0.25 },
			c: { x: x + Hex.size*0.5, y: y + Hex.size*0.25 },
			d: { x: x, y: y + Hex.size*0.5 },
			e: { x: x - Hex.size*0.5, y: y + Hex.size*0.25 },
			f: { x: x - Hex.size*0.5, y: y - Hex.size*0.25 },
		}
	}

	getLinkPosition(dir, center = false) {
		let x,y
		if (dir == 'east') {
			x = this.x + Hex.size
			y = this.y
		}
		if (dir == 'sw') {
			x = this.x - Hex.size*0.5
			y = this.y + Hex.size*0.75
		}
		if (dir == 'west') {
			x = this.x - Hex.size
			y = this.y
		}
		if (dir == 'nw') {
			x = this.x - Hex.size*0.5
			y = this.y - Hex.size*0.75
		}
		if (dir == 'ne') {
			x = this.x + Hex.size*0.5
			y = this.y - Hex.size*0.75
		}
		if (dir == 'se') {
			x = this.x + Hex.size*0.5
			y = this.y + Hex.size*0.75
		}
		if (center) {
			x += center.x
			y += center.y
		}
		//console.log(`new tile would be at (${x},${y})`)
		return {x,y}
	}

	isLinkOutOfBounds(dir, center, size) {
		//console.log('Checking link from', this, 'via', dir, 'with center', center, 'and size', size)
		let pos = this.getLinkPosition(dir, center)
		let coords = Hex.getEdgeCoords(pos.x, pos.y)
		//console.log('edge coords:', coords)
		// right most: b.x
		if (coords.b.x >= size.width) {
			//console.log('past right edge')
			return true
		}
		// left most: f.x
		if (coords.f.x <= 0) {
			//console.log('past left edge')
			return true
		}
		// top most: a.y
		if (coords.a.y <= 0) {
			//console.log('past top edge')
			return true
		}
		// bottom most: d.y
		if (coords.d.y >= size.height) {
			//console.log('past bottom edge')
			return true
		}
		return false
	}
}

export default Hex