class DoubleArray {
	constructor() {
		this.neg = []
		this.pos = []
	}

	getIndex(i) {
		if (!isNaN(i)) {
			i = parseInt(i, 10)
		}
		if (i<0) return this.neg[Math.abs(i)]
		return this.pos[i]
	}

	setIndex(i, value) {
		if (!isNaN(i)) {
			i = parseInt(i, 10)
		}
		if (i<0) 
			this.neg[Math.abs(i)] = value
		else
			this.pos[i] = value
	}
}

export default DoubleArray

/*
const doublearray = {
	neg: ['z', 'y', 'x', 'w', 'v', 'u'],
  pos: ['a', 'b', 'c', 'd', 'e', 'f']
};
const proxy = new Proxy(doublearray, {
	get(target, prop) {
  	if (!isNaN(prop)) {
    	prop = parseInt(prop, 10);
    }
    if (prop < 0)
    	return target.neg[Math.abs(prop)];
    else
    	return target.pos[prop];
  }
});

console.log(proxy[-3]);
*/