<script setup>
import { defineProps, computed } from 'vue'
import Hex from '../classes/Hex'

const props = defineProps({
	tile: Object,
	center: Object,
})
//console.log(`tile at ${props.tile.x},${props.tile.y}`)

const offset = { x: 0-Math.round(Hex.size*0.5), y: 0-Math.round(Hex.size*0.5) }
const points = Hex.getPolygon(offset)

const position = computed(() => ({
	x: props.center.x + props.tile.x + offset.x,
	y: props.center.y + props.tile.y + offset.y
}))
</script>

<template>
	<div
		:class="{
			tile: true,
		}"
		:style="{left: position.x+'px', top: position.y+'px'}"
		:height="Hex.size" 
		:width="Hex.size"
	>
		<svg :height="Hex.size" :width="Hex.size" class="hex">
			<polygon
				:points="points"
				stroke-width="1"
			/>
		</svg>
		<div class="number">
			<span>{{props.tile.id}}</span>
		</div>
	</div>
</template>

<style scoped>

.tile {
	position: absolute;
}
.tile > * {
	position: absolute;
	left: 0;
	top:0;
	height: 44px;
	width: 44px;
}

.tile svg {
	pointer-events: fill;
}

.tile svg polygon {
	stroke: #666666;	
	pointer-events: fill;
	fill: transparent;
}

.number {
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	color: white;
}
</style>