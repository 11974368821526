<script setup>
import { ref, computed, watch, onMounted, onUnmounted, onBeforeUpdate } from 'vue'
import HexTile from './HexTile.vue'
import Hex from '../classes/Hex'
import HexGrid from '../classes/HexGrid'
import Starfield from '../classes/Starfield';
import FakeTile from './FakeTile.vue'

let id = 0
let grid = new HexGrid()

let helpCenter = { x: 66, y: 66 }
let helpTiles = []

const tiles = ref([])
const tileRefs = ref([])
const hasWon = ref(false)
const hasLost = ref(false)
const flagCount = ref(0)
const revealedCount = ref(0)
const windowWidth = ref(window.innerWidth - 10)
const windowHeight = ref(window.innerHeight - 60)
const difficulty = 6 // default: 6

const showHelp = ref(false)
const helpX = ref(0)
const helpY = ref(0)
const checked = ref(false)

const center = computed(() => ({ x: Math.floor(windowWidth.value/2), y: Math.floor(windowHeight.value/2)}))
const total = computed(() => tiles.value.length)
const mineCount = computed(() => Math.floor((total.value)/difficulty) )
const size = computed(() => ({ width: windowWidth.value, height: windowHeight.value }))

const getRandomInt = (max) => Math.floor(Math.random() * max)
const getTile = (id) => tiles.value[id]

/* TODO:
	- hotkey for clearing adjacent tiles
	- break up GameBoard into smaller components
	- add a winning animation
	- use Canva for logo / favicon?
*/

function addTile(prev = null, dir = null) {
	//if (id > 0) return false
	const newTile = new Hex(id)
	tiles.value[id] = newTile
	if (prev && dir) {
		newTile.link(prev, dir, true)
	}
	grid.setTile(newTile.q, newTile.r, newTile)
	id++
	return newTile
}

function doesTileExist(prev, dir) {
	let pos = prev.getAxial(dir)
	let tile = grid.getTile(pos.q, pos.r)
	if (tile) return tile
	return false
}

/*function addRing(depth = 0, prev = null) {
	if (depth === 0) {
		return addTile()
	}
	let n
	prev = addTile(prev, 'east')
	for (n = 0; n < depth-1; n++) prev = addTile(prev, 'se')
	for (n = 0; n < depth; n++) prev = addTile(prev, 'sw')
	for (n = 0; n < depth; n++) prev = addTile(prev, 'west')
	for (n = 0; n < depth; n++) prev = addTile(prev, 'nw')
	for (n = 0; n < depth; n++) prev = addTile(prev, 'ne')
	for (n = 0; n < depth; n++) prev = addTile(prev, 'east')
	return prev
}*/

function  expandInDirection(prev, dir) {
	let newTile, tile
	if (!prev[dir] && !prev.isLinkOutOfBounds(dir, center.value, size.value)) {
		tile = doesTileExist(prev, dir)
		if (tile) {
			prev.link(tile, dir)
			prev.linkAdjacent(doesTileExist)
		} else {
			newTile = addTile(prev, dir)
			if (newTile) {
				newTile.linkAdjacent(doesTileExist)
				expand(newTile)
			}
		}
	}
}

function expand(prev) {
	Hex.dirs.forEach((dir) => expandInDirection(prev, dir))
}

function initBoard(reset = false) {
	console.log('initializing board...')
	hasLost.value = false
	hasWon.value = false
	flagCount.value = 0
	revealedCount.value = 0
	let prev, n
	if (!reset) {
		prev = addTile()
		//console.log('prev', prev)
		expand(prev)
	}
	placeMines(mineCount.value)
	for (n = 0; n < tiles.value.length; n++) {
		calcAdjacent(tiles.value[n])
	}
	console.log('board initialized.')
	console.log(`center: (${center.value.x},${center.value.y})`)
	console.log('setting starfield background')
	setBackground()
}

function placeMines(count) {
	//console.log(`placing ${count} mines`)
	if (!count) return
	const m = getRandomInt(total.value) // get a random tile
	const tile = tiles.value[m]
	if (tile.mine) return placeMines(count) // check if there is already a mine present
	tile.mine = true // place the mine
	return placeMines(count-1)
}

function calcAdjacent(tile) {
	let count = 0
	if (tile.east?.mine) count++
	if (tile.se?.mine) count++
	if (tile.sw?.mine) count++
	if (tile.west?.mine) count++
	if (tile.nw?.mine) count++
	if (tile.ne?.mine) count++
	tile.adjacent = count
}

function clearBoard() {
	if (!tiles.value || !tiles.value.length) return // nothing to do
	id = 0
	grid = new HexGrid()
	for (var n = tiles.value.length-1; n >= 0; n--) {
		delete tiles.value.pop()
	}
}

function reset() {
	console.log('resetting board...')
	for (var n = 0; n < tiles.value.length; n++) {
		let tile = getTile(n)
		tile.revealed = false
		tile.mine = false
		tile.flagged = false
		tile.adjacent = 0
	}
	initBoard(true)
}

function checkWinCondition() {
	/*console.log(
		'revealedCount', revealedCount.value,
		'mineCount', mineCount.value,
		'total', total.value,
	)*/
	if ((revealedCount.value + mineCount.value) === total.value) {
		hasWon.value = true
		revealAll()
		return true
	}
	return false
}

function markRevealed(tile) {
	if (hasWon.value && tile.mine && !tile.revealed) {
		tile.flagged = true // flag it instead
		return
	}
	if (tile.flagged && !tile.mine) {
		flagCount.value--
		tile.flagged = false
	}
	tile.revealed = true
	revealedCount.value++
}

function revealAdjacent(tile) {
	if (!tile) return false
	if (tile.revealed) return false // already revealed
	if (tile.adjacent > 0) {
		markRevealed(tile)
		return false // have reached an edge
	}
	if (tile.adjacent === 0) {
		markRevealed(tile)
		revealAdjacent(tile.east)
		revealAdjacent(tile.se)
		revealAdjacent(tile.sw)
		revealAdjacent(tile.west)
		revealAdjacent(tile.nw)
		revealAdjacent(tile.ne)
	}
}

function revealAll() {
	for (var n = 0; n < tiles.value.length; n++) {
		markRevealed(getTile(n))
	}
}

function reveal(e, id) {
	if (checked.value) return flag(e, id) // touch mode toggle
	e.preventDefault()
	const tile = getTile(id)
	if (tile.flagged) return // do nothing if the tile is flagged
	if (tile.mine) { // user has lost
		hasLost.value = true
		revealAll()
		return
	}
	revealAdjacent(tile) // reveal all connected tiles with 0 adjacent mines
	checkWinCondition()
}

function flag(e, id) {
	e.preventDefault()
	const tile = getTile(id)
	if (tile.revealed) return // do nothing if the tile is already revealed
	if (tile.flagged) {
		flagCount.value--
		tile.flagged = false
	} else {
		flagCount.value++
		tile.flagged = true
	}
	checkWinCondition()
}

function moveTo(id) {
	//console.log(`moving to tile ${id}`, tiles.value[id])
	const tileRef = tileRefs.value[id]	
	//console.log('tileRef:', tileRef)
	tileRef.focus()
}

function setRef(id, el) {
	//console.log(`setting ref for tile ${id}`, el)
	tileRefs.value[id] = el
}

function toggleHelp() {
	helpX.value = Math.floor(windowWidth.value/2) - 125
	helpY.value = 50
	showHelp.value = !showHelp.value
}

function initHelp() {
	let config = [
		{ key: 'd', dir: 'east'},
		{ key: 'x', dir: 'se'},
		{ key: 'z', dir: 'sw'},
		{ key: 'a', dir: 'west'},
		{ key: 'w', dir: 'nw'},
		{ key: 'e', dir: 'ne'},
	]
	let prev = new Hex(' ')
	helpTiles[0] = prev
	config.forEach((tile) => {
		helpTiles.push(new Hex(tile.key))
		helpTiles[helpTiles.length-1].link(prev, tile.dir, true)
	})
}

// remove and recreate board with new size
watch([windowHeight, windowWidth], () => {
	console.log('resziing board...')
	clearBoard()
	initBoard(false)
})

// change board size when screen resized
const onResize = () => {
	windowWidth.value = window.innerWidth - 10
	windowHeight.value = window.innerHeight - 60
}

onMounted(() => {
	window.addEventListener('resize', onResize)
	initBoard()
	initHelp()
})

onBeforeUpdate(() => {
	tileRefs.value = []
})

onUnmounted(() => {
	window.removeEventListener('resize', onResize)
})

function setBackground() {
	let size = Math.max(Math.max(window.innerWidth, window.innerHeight) * 1.5, 4000)
	let backgroundImage = Starfield.getDataUrl(size)
	document.getElementsByTagName('body')[0].style.backgroundImage = backgroundImage
}
</script>

<template>
	<div class="controls">
		<input type="checkbox" id="checkbox" v-model="checked" tabindex="0"/>
		<span v-if="(!hasWon && !hasLost)" class="info">Mines: {{mineCount - flagCount}}</span>
		<h3 v-if="hasLost" class="hasLost">You have lost!</h3>
		<h3 v-else-if="hasWon" class="hasWon">You have won!</h3>
		<span class="helpIcon" tabindex="0" alt="help"
			@click="toggleHelp()"
			@keyup.enter="toggleHelp()"
			@keyup.space="toggleHelp()"
		>?</span>
		<button @click="reset" tabindex="0">Reset</button>
	</div>
	<div class="board" :style="{ height: windowHeight+'px', width: windowWidth+'px'}">
		<HexTile 
			v-for="tile in tiles" 
			:key="tile.id" 
			:tile="tile" 
			:center="center"
			:reveal="reveal"
			:flag="flag"
			:moveTo="moveTo"
			:setRef="setRef"
		/>
	</div>
	<div v-if="showHelp" class="help" :style="{ left: helpX+'px', top: helpY+'px'}">
		<div><strong>Reveal tile:</strong> Left-click or &lt;enter&gt;</div>
		<div><strong>Flag tile:</strong> Right-click or &lt;space&gt;</div>
		<div><strong>Navigate:</strong> &lt;tab&gt; or keys below</div>
		<div class="navigation">
			<FakeTile 
				v-for="tile in helpTiles"
				:key="tile.id"
				:tile="tile"
				:center="helpCenter"
			/>
		</div>
		<div>Use checkbox to toggle flag mode on touch devices</div>
	</div>
</template>

<style scoped>
.board {
	/*border: 2px dashed #fff;*/
	position: relative;
}
.controls {
	/*background-color: #66666680;*/
	background-color: transparent;
	color: white;
	/*margin: 0.5em auto;*/
	/*padding: 1em;*/
	height: 60px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.controls > * {
	margin: 0 1em;
	display: inline;
}
.controls button {
	background-color: #666;
	border: 2px solid #999;
	color: white;
	cursor: pointer;
	height: 30px;
	padding: 0 1em;
	font-size: medium;
}
.controls button:hover {
	background-color: #999;
}
input[type=checkbox] {
  appearance: none;
  background-color: #00800080;
  /*margin: 0;*/
	-webkit-appearance: none;
	font: inherit;
  color: #999;
  width: 30px;
  height: 30px;
  border: 2px solid #008000;
  /*border-radius: 0.15em;*/
  transform: translateY(2px);
  display: inline-grid !important;
  place-content: center;
	cursor: pointer;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.85em;
  height: 0.85em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em white;
	transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}
input[type=checkbox] + button {
	margin-left: 2em;
}
input:focus, button:focus, .helpIcon:focus {
	outline: 2px solid white;
}
.hasLost, .hasWon, .info, .navigation {
	font-family: 'Bungee Hairline', cursive;
  font-weight: bold;
}
.hasLost {
	color: white;
}
.hasWon {
	color: limegreen;
}
.helpIcon {
	color: white;
	border: 2px solid white;
	border-radius: 2em;
	padding: 0 5px;
	cursor: pointer;
	font-weight: bold;
}
.help {
	background-color: #333;
	border: 2px solid black;
	color: #CCC;
	padding: 1em;
	position: absolute;
	text-align: center;
	width: 250px;
	line-height: 1.5;
}
.help strong {
	color: white;
}
.navigation {
	position: relative;
	height:132px;
	width: 132px;
	/*border: 1px dashed white;*/
	margin: auto;
}

@media (max-width: 480px) {
	.controls {
		justify-content: space-between;
	}
	.hasLost, .hasWon, .info {
		flex: 1 3 40%;
	}
	
	.controls > *:not(.helpIcon) {
		margin: 0 0.5em;
	}
}

@media (max-width: 320px) {
	.controls > *:not(.helpIcon) {
		margin: 0;
	}
}
</style>