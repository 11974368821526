<script>
import GameBoard from './components/GameBoard.vue'

export default {
  name: 'App',
  components: {
    GameBoard
  }
}
</script>

<template>
  <GameBoard/>
</template>

<style>
body {
  background: black;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /*font-family: 'Bungee Hairline', cursive;
  font-weight: bold;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
}
</style>
