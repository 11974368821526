<script setup>
import { defineProps, computed } from 'vue'
import IconFlag from './IconFlag.vue'
import IconMine from './IconMine.vue'
import Hex from '../classes/Hex'

const props = defineProps({
	tile: Object,
	center: Object,
	reveal: Function,
	flag: Function,
	moveTo: Function,
	setRef: Function,
})
//console.log(`tile at ${props.tile.x},${props.tile.y}`)

const offset = { x: 0-Math.round(Hex.size*0.5), y: 0-Math.round(Hex.size*0.5) }
const points = Hex.getPolygon(offset)

const position = computed(() => ({
	x: props.center.x + props.tile.x + offset.x,
	y: props.center.y + props.tile.y + offset.y
}))

function keyHandler(e) {
	//console.log(`${props.tile.id} event:`, e)
	switch(e.code) {
		case 'Enter': 
			props.reveal(e, props.tile.id)
			break
		case 'Space':
			props.flag(e, props.tile.id)
			break
		case 'KeyD':
			if (props.tile.east) props.moveTo(props.tile.east.id)
			break
		case 'KeyX':
			if (props.tile.se) props.moveTo(props.tile.se.id)
			break
		case 'KeyZ':
			if (props.tile.sw) props.moveTo(props.tile.sw.id)
			break
		case 'KeyA':
			if (props.tile.west) props.moveTo(props.tile.west.id)
			break
		case 'KeyW':
			if (props.tile.nw) props.moveTo(props.tile.nw.id)
			break
		case 'KeyE':
			if (props.tile.ne) props.moveTo(props.tile.ne.id)
			break
	}
}

</script>

<template>
	<div
		:class="{
			revealed: props.tile.revealed,
			flagged: props.tile.flagged,
			tile: true,
			mine: props.tile.revealed && props.tile.mine,
			'revealed-0': props.tile.adjacent === 0 && !props.tile.mine && props.tile.revealed,
			'revealed-1': props.tile.adjacent === 1 && !props.tile.mine && props.tile.revealed,
			'revealed-2': props.tile.adjacent === 2 && !props.tile.mine && props.tile.revealed,
			'revealed-3': props.tile.adjacent === 3 && !props.tile.mine && props.tile.revealed,
			'revealed-4': props.tile.adjacent === 4 && !props.tile.mine && props.tile.revealed,
			'revealed-5': props.tile.adjacent === 5 && !props.tile.mine && props.tile.revealed,
			'revealed-6': props.tile.adjacent === 6 && !props.tile.mine && props.tile.revealed,
		}"
		:style="{left: position.x+'px', top: position.y+'px'}"
		:tabindex="0"
		@keyup="keyHandler($event)"
		:ref="el => { props.setRef(props.tile.id, el) }"
		:height="Hex.size" 
		:width="Hex.size"
	>
		<svg :height="Hex.size" :width="Hex.size" class="hex">
			<polygon
				:points="points"
				stroke-width="1"
			/>
		</svg>
		<div class="number">
			<span v-if="props.tile.flagged" class="icon"><IconFlag alt="Flagged"/></span>
			<!--<span v-else-if="!props.tile.revealed">{{props.tile.q}},{{props.tile.r}}</span>-->
			<span v-else-if="props.tile.revealed && props.tile.mine" class="icon"><IconMine alt="Mine"/></span>
			<span v-else-if="props.tile.revealed">{{props.tile.adjacent ? props.tile.adjacent : ''}}</span>
		</div>
		<div class="outerClickbox" v-if="!props.tile.revealed">
			<div 
				class="clickbox"
				@click="props.reveal($event, props.tile.id)"
				@keyup.enter="props.reveal($event, props.tile.id)"
				@click.right="props.flag($event, props.tile.id)"
				@keyup.space="props.flag($event, props.tile.id)"
			></div>
		</div>
	</div>
</template>

<style scoped>

.tile {
	position: absolute;
}
.tile:focus {
	outline: none;
}
.tile > * {
	position: absolute;
	left: 0;
	top:0;
	height: 44px;
	width: 44px;
}
.tile svg {
	pointer-events: fill;
}

.tile svg polygon {
	stroke: #666666;
	/*stroke-dasharray: 3;*/
	
	pointer-events: fill;

}
.tile:not(.revealed, .flagged) svg polygon {
	fill: #CCCCCC40;
	stroke: #CCCCCC60;
}
.number {
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	color: white;
}
.outerClickbox {
	display: flex;
	align-items: center;
	justify-content: center;
}
.clickbox {
	width: 70%;
	height: 70%;
	/*border: 1px solid orange;*/
	cursor: pointer;
	/*border-radius: 1em;*/
}

.revealed {
	/*background-color: white;*/
	cursor: default;
}
.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60%;
	height: 60%;
	color: #ffffff;
}
.mine svg polygon {
	fill: #ff000060;
	stroke: #ff000080;
}
.flagged svg polygon {
	fill: #00800060;
	stroke: #00800080;
}
.flagged.revealed:not(.mine) svg polygon {
	/*background-color: gold;*/
	fill: #FFD70080;
	stroke: #FFD700;
}
.revealed-0 svg polygon {
	fill: transparent;
	stroke: #CCCCCC20;
}
.revealed-1 svg polygon {
	fill: transparent;
	stroke: #CCCCCC20;
}
.revealed-2 svg polygon {
	/* dodger blue */
	fill: #1E90FF60;
	stroke: #1E90FF80;
}
.revealed-3 svg polygon {
	/* medium blue */
	fill: #0000CD60;
	stroke: #0000CD80;
}
.revealed-4 svg polygon {
	/* dark violet */
	fill: #9400D360;
	stroke: #9400D380;
}
.revealed-5 svg polygon {
	/* magenta */
	fill: #FF00FF60;
	stroke: #FF00FF80;
}
.revealed-6 svg polygon {
	/* dark orange */
	fill: #FF8C0060;
	stroke: #FF8C0080;
}

.tile:focus svg polygon {
	stroke: white;
	stroke-width: 2px;
	stroke-linejoin: round;
}
</style>