class Starfield {
	static density = 0.0002 // 0.00004

	static getRandomInt(min, max) {
		min = Math.ceil(min)
		max = Math.floor(max)
		return Math.floor(Math.random() * (max - min + 1)) + min
	}
	
	static getWeightedRandom(spec) {
		let i, sum=0, r=Math.random()
		for (i in spec) {
			sum += spec[i]
			if (r <= sum) return i
		}
	}
	
	static getRandomColorValue() {
		//return random(25,75)/100
		return Math.random()
	}

	static createStar(size) {
		return "<circle fill='url(%23star)' cx='"+Starfield.getRandomInt(0,size)+"' cy='"+Starfield.getRandomInt(0,size)+"' r='"+Starfield.getWeightedRandom({2:0.6, 3:0.3, 4:0.1})+"'></circle>"
	}

	static createNebula(size, seed, colorMatrix) {
		if (!seed) {
			seed = Math.random()*1000
		}
		if (!colorMatrix) {
			//colorMatrix = ".25 .25 .25 0 0 .5 .5 .5 0 0 .5 .5 .5 0 0 0 0 0 .15 0";
			//var r = Starfield.getRandomColorValue();
			//var g = Starfield.getRandomColorValue();
			//var b = Starfield.getRandomColorValue();
			//colorMatrix = r+" "+r+" "+r+" 0 0 "+g+" "+g+" "+g+" 0 0 "+b+" "+b+" "+b+" 0 0 0 0 0 .5 0";
			colorMatrix = Starfield.getRandomColorValue()+" "+Starfield.getRandomColorValue()+" "+Starfield.getRandomColorValue()+" 0 0 "+Starfield.getRandomColorValue()+" "+Starfield.getRandomColorValue()+" "+Starfield.getRandomColorValue()+" 0 0 "+Starfield.getRandomColorValue()+" "+Starfield.getRandomColorValue()+" "+Starfield.getRandomColorValue()+" 0 0 0 0 0 .25 .0125";
		}
		let freq = 5/(size/2)
		return {
			defs: "<filter id='nebula' filterUnits='userSpaceOnUse' x='0' y='0' height='"+(size/2)+"' width='"+(size/2)+"'><feTurbulence baseFrequency='"+freq+"' type='fractalNoise' numOctaves='5' seed='"+seed+"' result='r1'/><feColorMatrix in='r1' type='matrix' values='"+colorMatrix+"'/></filter>",
			data :  "<rect x='0' y='0' height='"+(size/2)+"' width='"+(size/2)+"' filter='url(%23nebula)'></rect>" +
							"<rect x='0' y='0' height='"+(size/2)+"' width='"+(size/2)+"' filter='url(%23nebula)' transform='translate("+size+",0) scale(-1,1)'></rect>" +
							"<rect x='0' y='0' height='"+(size/2)+"' width='"+(size/2)+"' filter='url(%23nebula)' transform='translate(0,"+size+") scale(1,-1)'></rect>" +
							"<rect x='0' y='0' height='"+(size/2)+"' width='"+(size/2)+"' filter='url(%23nebula)' transform='translate("+size+","+size+") scale(-1,-1)'></rect>"
		}
	}

	static create(size) {
		let nebula = Starfield.createNebula(size)
		let data = "<svg xmlns='http://www.w3.org/2000/svg' width='"+size+"' height='"+size+"' viewBox='0 0 "+size+" "+size+"'><defs><radialGradient id='star'><stop offset='10%' stop-color='white'/><stop offset='100%' stop-color='transparent'/></radialGradient>"+nebula.defs+"</defs>"
		let count = (size*size)*Starfield.density
		for (let i = 0; i < count; i++) {
			data += Starfield.createStar(size)
		}
		data += nebula.data
		data += "</svg>"
		return data
	}

	static getDataUrl(size) {
		let data = Starfield.create(size)
		let dataurl = "data:image/svg+xml;utf8,"+data
		return 'url("'+dataurl+'")'
	}
	
	/*document.addEventListener("DOMContentLoaded", function(event) {
		var size = Math.min(Math.max(Math.max(window.innerWidth, window.innerHeight) * 1.5, 2000), 4000);
		var backgroundImage = 'url("'+createStarfield(size)+'")';
		document.getElementsByTagName("body")[0].style.backgroundImage = backgroundImage;
	});*/

}

export default Starfield