import DoubleArray from './DoubleArray'

class HexGrid {
	constructor() {
		this.data = new DoubleArray()
	}

	getTile(q, r) {
		//console.log(`getTile(${q},${r})`, this)
		let tile = this.data?.getIndex(q)?.getIndex(r)
		if (tile) return tile
		return false
	}

	setTile(q, r, value) {
		if (!this.data.getIndex(q)) {
			// add second dimension (r) array if it does not exist
			this.data.setIndex(q, new DoubleArray())
		}
		this.data.getIndex(q).setIndex(r, value)
	}
}

export default HexGrid